import { useState, useEffect } from "react";
import styled from "styled-components";
import { apiClient } from "../../../utils/apiClient";
import { useAuth0 } from "@auth0/auth0-react";

import { fontSizes, colors } from "../../Tokens";
import { Container } from "../../Container";
import { Row, AutoWidth, CustomWidth } from "../../Grid";
import Header from "../../Header";
import {
  CartDrawer,
  FilterDrawer,
  NoteDrawer,
  HistoryDrawer,
} from "../../Drawer";
import { FilterPanel } from "../../FilterPanel";
import { PortalProductListing } from "../../PortalProduct";
import { Wrapper } from "../../Wrapper";
import { OrderList } from "../../OrderList";
import Skeleton from "react-loading-skeleton";
import { Title, SubTitle } from "../../Title";
import "react-loading-skeleton/dist/skeleton.css";

const ListContainer = styled(Container)`
  padding-top: 157px;

  @media screen and (min-width: 768px) {
    padding-top: 97px;
  }
  @media screen and (max-width: 768px) {
    align: center;
  }
`;

const Note = styled.div`
  font-size: ${fontSizes.xsmall};
  max-width: 80%;
  background-color: white;
  height: auto;
  white-space: pre-wrap;
  color: ${colors.purple};
  margin-top: 6rem;
  border-radius: 4px;
  position: absolute;
  left: 5rem;
  padding: 2rem 2rem;
  width: 80%;
`;

const HistoryContainer = styled.div`
  font-size: ${fontSizes.xsmall};
  max-width: 100%;
  width: 100%;
  background-color: white;
  white-space: pre-wrap;
  color: ${colors.purple};
  margin-top: 7rem;
  position: absolute;
  padding: 2rem 2rem;
  overflow-y: auto;
  top: 1.8rem;
  bottom: 0;
`;

const InvoiceHistoryItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d8d8d8;
  font-size: 1.6rem;
  cursor: pointer;

  margin-top: 2rem;

  background-color: ${colors["grey-light"]};
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  > div {
    display: block;
    flex: 1;

    &.product__details {
      flex: 2;
      font-size: 1.4rem;
      max-width: 33%;
      @media screen and (min-width: 480px) {
        max-width: none;
      }
    }
    &.product__price {
      font-size: 1.2rem;
    }
    &.product__form {
      text-align: right;
      display: flex;

      > div {
        margin: 0;
        position: static;
      }

      input,
      button {
        height: 40px;
        font-size: 16px;
        padding: 0 1.2rem;
        margin: 0;
        text-align: center;
      }

      button {
        white-space: nowrap;
        margin-left: 1rem;
        border-width: 2px;
      }
    }
  }
`;

function Landing() {
  const [cart, setCart] = useState({
    products: [],
    description: null,
    open: false,
  });
  const [filter, setFilter] = useState({ open: false });
  const [note, setNote] = useState({ open: true });
  const [history, setHistory] = useState({ open: false });
  const [notes, setNotes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cats, setCats] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [products] = useState([]);
  const [cartHistory, setCartHistory] = useState([]);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const functions = {
    openCart: () => setCart((prev) => ({ ...prev, open: !prev.open })),
    openNote: () => setNote((prev) => ({ ...prev, open: !prev.open })),
    openHistory: () => setHistory((prev) => ({ ...prev, open: !prev.open })),
    openFilter: () => setFilter((prev) => ({ ...prev, open: !prev.open })),
    remove: (index) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts.splice(index, 1);
        return { ...prev, products: newProducts };
      });
    },
    clear: () => setCart((prev) => ({ ...prev, products: [] })),
    update: (index, qty) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts[index] = { ...newProducts[index], qty: Number(qty) };
        return { ...prev, products: newProducts };
      });
    },
    add: (row, qty) => {
      setCart((prev) => {
        const existingIndex = prev.products.findIndex((p) => p.id === row.id);
        if (existingIndex >= 0) {
          const newProducts = [...prev.products];
          newProducts[existingIndex] = {
            ...newProducts[existingIndex],
            qty: newProducts[existingIndex].qty + Number(qty),
          };
          return { ...prev, products: newProducts };
        } else {
          return {
            ...prev,
            products: [...prev.products, { ...row, qty: Number(qty) }],
          };
        }
      });
    },
    total: () =>
      cart.products.reduce((sum, row) => {
        const brutoPrijs = parseFloat(
          row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
        );
        return sum + brutoPrijs * row.qty;
      }, 0),
    addBrands: (brand) => {
      setBrands((prev) => {
        if (prev.includes(brand)) {
          return prev.filter((b) => b !== brand);
        } else {
          return [...prev, brand];
        }
      });
    },
    addCats: (cat) => {
      setCats((prev) => {
        if (prev.includes(cat)) {
          return prev.filter((c) => c !== cat);
        } else {
          return [...prev, cat];
        }
      });
    },

    searchCartProducts: async (userId, page, searchTerm, qty) => {
      const filterRes = await apiClient.portalProducts.list(
        userId,
        searchTerm,
        brands,
        cats,
        page
      );

      return filterRes.data; // Change based on actual API response structure
    },
  };

  useEffect(() => {
    const getNotes = async () => {
      try {
        const notes = await apiClient.portalProducts.getNotes();
        const noteList = notes.data.map((a) => a.opmerkingen);
        setNotes(noteList.join("\n"));
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };
    getNotes();
  }, []);

  useEffect(() => {
    const getCartHistory = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://media-service-group.eu.auth0.com/api/v2/",
          },
        });

        const response = await apiClient.cart.getCart({
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        setCartHistory(response.data);
      } catch (error) {
        console.error("Error fetching cart history:", error);
      }
    };
    getCartHistory();
  }, [getAccessTokenSilently]);

  // Function to handle the click on the InvoiceHistoryItem and store product data + qty
  const handleInvoiceHistoryClick = async (cartItem) => {
    functions.clear();
    if (Array.isArray(cartItem.products) && cartItem.products.length > 0) {
      const productResults = await Promise.all(
        cartItem.products.map(async (product) => {
          const searchResult = await functions.searchCartProducts(
            [],
            [],
            product.id
          );
          // Return product details along with the qty
          return { ...searchResult, qty: product.qty };
        })
      );

      // For each product in productResults, call functions.add with the product data and qty
      productResults.forEach((product) => {
        const productDetails = product["0"]; // Access the product details nested under key "0"

        // Calculate discount
        const discount =
          1 - parseFloat(productDetails.korting.replace("%", "")) / 100;
        const originalPrice = parseFloat(
          productDetails.brutoprijs.replace(",", ".")
        );
        const discountedPrice = discount * originalPrice;

        // Handle price formatting based on login status (like checkLoginPrice)
        let finalPrice;
        if (isAuthenticated) {
          // User is logged in, apply discount and format brutoprijs
          finalPrice = discountedPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          // User is not logged in, show original price without discount
          finalPrice = productDetails.brutoprijs.replace(".", ",");
        }

        // Call functions.add with the updated brutoprijs (discounted or original, depending on auth status)
        functions.add(
          {
            id: productDetails.id,
            omschrijving: productDetails.omschrijving,
            merk: productDetails.merk,
            brutoprijs: finalPrice, // Formatted price
            type: productDetails.type, // Include type if necessary
            korting: productDetails.korting,
          },
          product.qty // The corresponding quantity for each product
        );
      });

      // Close history and open the cart
      functions.openHistory(false);
      functions.openCart();
      console.log(productResults); // For debugging
    }
  };
  return (
    <Wrapper style={{ backgroundColor: "#C1C1C199" }}>
      <Header
        searchTerm={setCurrentSearchTerm}
        products={cart.products}
        functions={functions}
      />

      <ListContainer>
        <Row>
          <AutoWidth>
            <CustomWidth width={"200px"}>
              <FilterPanel
                products={products}
                addCats={functions.addCats}
                addBrands={functions.addBrands}
              />
            </CustomWidth>

            {isAuthenticated ? (
              <PortalProductListing
                brands={brands}
                cats={cats}
                products={products}
                functions={functions}
                searchTerm={currentSearchTerm}
              />
            ) : (
              <Title style={{ paddingTop: "40%" }}>
                Log in om toegang te krijgen tot het Media Service Portal.
              </Title>
            )}
          </AutoWidth>
          <CartDrawer
            className="modal"
            side="right"
            open={cart.open}
            closeDrawer={functions.openCart}
            style={{}}
          >
            <OrderList state={cart} functions={functions} />
          </CartDrawer>

          <NoteDrawer
            className="modal"
            side="right"
            open={note.open}
            closeDrawer={functions.openNote}
          >
            <Note>
              <strong>Release Notes: </strong>
              <br />
              <br />
              {notes.length !== 0 ? (
                notes
              ) : (
                <Skeleton count={10} height={10} borderRadius={"7px"} />
              )}
            </Note>
          </NoteDrawer>

          <HistoryDrawer
            className="modal"
            side="right"
            open={history.open}
            closeDrawer={functions.openHistory}
          >
            <HistoryContainer>
              <strong>Recente offertes:</strong>
              {cartHistory.map((cartItem, index) => (
                <InvoiceHistoryItem
                  key={index}
                  onClick={() => handleInvoiceHistoryClick(cartItem)}
                >
                  <div className="product__details">
                    <strong>Offerte #{index + 1}:</strong>
                    <SubTitle style={{ fontSize: "1.5rem" }}>
                      {Array.isArray(cartItem.products) &&
                      cartItem.products.length > 0 ? (
                        cartItem.products.map((product) => (
                          <li key={product.id}>
                            Artikelnummer: {product.id}, Aantal: {product.qty}
                          </li>
                        ))
                      ) : (
                        <li>No products in the cart.</li>
                      )}
                    </SubTitle>
                  </div>
                </InvoiceHistoryItem>
              ))}
            </HistoryContainer>
          </HistoryDrawer>

          <FilterDrawer
            className="modal"
            side="right"
            open={filter.open}
            closeDrawer={functions.openFilter}
          >
            <FilterPanel
              products={products}
              addCats={functions.addCats}
              addBrands={functions.addBrands}
            />
          </FilterDrawer>
        </Row>
      </ListContainer>
    </Wrapper>
  );
}

export default Landing;
